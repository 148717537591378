<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>SOLICITANTE</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_solicitante.tipo_id" :rules="[rules.requerido]" :items="items.tiposolicitantes" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE SOLICITANTE *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="form_solicitante.nombre" :rules="[rules.requerido]" maxlength="50" counter="50" :color="colores.primario" label="NOMBRE COMPLETO DE LA PERSONA QUE RADICA *"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="form_solicitante.telefono" :rules="[rules.telefono]" maxlength="10" counter="10" :color="colores.primario" label="TELÉFONO / CELULAR"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="form_solicitante.correo" :rules="[rules.requerido, rules.email]" maxlength="100" counter="100" :color="colores.primario" label="CORREO ELECTRÓNICO DE LA PERSONA QUE RADICA *"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>

export default {
  name: 'solicitanteComponent',
  components: {

  },
  props: ['colores', 'items', 'loadingData', 'rules', 'form_solicitante'],
  data: () => ({
    //
  })
}
</script>
